import React, { useState } from 'react'
import Layout from '../components/Layout'
import { BackgroundImageSection } from '../components/templates/BackgroundImageSection'
import { Hero } from '../components/templates/Hero'
import { BackgroundBreak } from '../components/templates/BackgroundBreak'
//import { ImageCarousel } from '../components/templates/ImageCarousel'
import { graphql } from 'gatsby'
import { ScrollableTable } from '../components/templates/ScrollableTable'
import {
  CaptionComponent,
  CaptionGroup,
} from '../components/templates/CaptionComponent'

const HomePage = ({ data }) => {
  const [showFullScreen, setShowFullScreen] = useState('setHeight')
  //const FoodImages = data.foodImages.nodes
  const setTableContent = () => {
    if (showFullScreen === 'setHeight') {
      return <ScrollableTable />
    } else {
      return <ScrollableTable fullPage />
    }
  }
  return (
    <Layout
      metaTitle={'Home | Pyry Palosaari'}
      metaDescription={
        'Welcome! This will be my masterpiece of the gentle art: Web Development.'
      }
    >
      <Hero captionLeft>
        <CaptionGroup>
          <CaptionComponent className="iam-caption" captionAnimated>
            I am
          </CaptionComponent>
          <CaptionComponent className="pedro-caption" captionAnimated>
            Pedro
          </CaptionComponent>
          <CaptionComponent className="changing-text-caption" captionAnimated>
            <span className="text-1">
              I do Web Development
              <span
                style={{
                  color: 'orange',
                }}
              >
                .
              </span>
            </span>
            <span className="text-2">
              I'm an Enterpreneur
              <span
                style={{
                  color: 'orange',
                }}
              >
                .
              </span>
            </span>
            <span className="text-3">
              I'm an asshole
              <span
                style={{
                  color: 'orange',
                }}
              >
                .
              </span>
            </span>
            <span className="text-4">
              Wait!? What did you say?
              <span
                style={{
                  color: 'orange',
                }}
              >
                .
              </span>
            </span>
            <span className="text-5">
              Gtfo my page!
              <span
                style={{
                  color: 'orange',
                }}
              >
                .
              </span>
            </span>
          </CaptionComponent>
        </CaptionGroup>
      </Hero>
      {/*<Img fluid={sources} />*/}
      <BackgroundBreak title={'Parallax Demo'}>
        Parallax scrolling is a web site trend where the background content is
        moved at a different speed than the foreground content while scrolling.
        Nascetur per nec posuere turpis, lectus nec libero turpis nunc at, sed
        posuere mollis ullamcorper libero ante lectus, blandit pellentesque a,
        magna turpis est sapien duis blandit dignissim. Viverra interdum mi
        magna mi, morbi sociis. Condimentum dui ipsum consequat morbi, curabitur
        aliquam pede, nullam vitae eu placerat eget et vehicula. Varius quisque
        non molestie dolor, nunc nisl dapibus vestibulum at, sodales tincidunt
        mauris ullamcorper, dapibus pulvinar, in in neque risus odio. Accumsan
        fringilla vulputate at quibusdam sociis eleifend, aenean maecenas
        vulputate, non id vehicula lorem mattis, ratione interdum sociis ornare.
        Suscipit proin magna cras vel, non sit platea sit, maecenas ante augue
        etiam maecenas, porta porttitor placerat leo.
      </BackgroundBreak>
      <BackgroundImageSection
        description="LOREM IPSUM"
        image={data.backgrounds.nodes[0].childImageSharp.fluid}
      />

      <BackgroundBreak>
        Parallax scrolling is a web site trend where the background content is
        moved at a different speed than the foreground content while scrolling.
        Nascetur per nec posuere turpis, lectus nec libero turpis nunc at, sed
        posuere mollis ullamcorper libero ante lectus, blandit pellentesque a,
        magna turpis est sapien duis blandit dignissim.
      </BackgroundBreak>
      {/*<BackgroundImage
        description="LOREM IPSUM"
        align="center"
        width={33.33}
        image={Bg2}
      />*/}
      {/*{data.allFile.edges.map(({ node }, i) => (
        <Img key={i} fluid={node.childImageSharp.fluid} alt={node.name} />
      ))}*/}
      <BackgroundImageSection
        align="right"
        description="LOREM IPSUM"
        image={data.backgrounds.nodes[0].childImageSharp.fluid}
      />
      {/*<ImageCarousel data={FoodImages} />*/}
      <span id="scrollable-table"></span>
      <div
        className="container"
        style={{ position: 'relative', boxSizing: 'content-box' }}
      >
        {/*<button
          className="switch-table-view"
          onClick={() => {
            setShowFullScreen(!showFullScreen)
          }}
        >
          Paina
        </button>*/}
        <div
          className="change-view-toggle"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div>
            <div
              onClick={() => {
                setShowFullScreen('setHeight')
              }}
              className={showFullScreen === 'setHeight' ? 'active' : undefined}
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                setShowFullScreen('setHeight')
              }}
            >
              Korkeus 30rem
            </div>
            <div
              onClick={() => {
                setShowFullScreen('fullPage')
              }}
              className={showFullScreen === 'fullPage' ? 'active' : undefined}
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                setShowFullScreen('fullPage')
              }}
            >
              Full Page View
            </div>
          </div>
        </div>
        {setTableContent()}
      </div>
      <BackgroundImageSection
        children
        image={data.backgrounds.nodes[1].childImageSharp.fluid}
      />
    </Layout>
  )
}

export const query = graphql`
  query ImageQueryIndex {
    foodImages: allFile(
      filter: {
        name: {
          in: ["IMG_1966", "IMG_2049", "IMG_2121", "IMG_2309", "IMG_7998"]
        }
        absolutePath: { regex: "/images/" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    backgrounds: allFile(
      filter: {
        name: { in: ["pyry2", "pyry5"] }
        absolutePath: { regex: "/images/" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default HomePage
