import React from 'react'

const CaptionComponent = (props) => {
  const {
    className,
    captionAnimated,
    captionLeft,
    captionAnimatedLeft,
    captionAnimatedRight,
    children,
  } = props

  const getCaptionClassName = () => {
    let classString = 'container caption-group-item '
    if (className) {
      classString += `${className}`
    }
    if (captionAnimatedLeft || captionAnimatedRight || captionAnimated) {
      classString += ' animated-caption'
    }
    if (captionAnimatedLeft) {
      classString += ' hero-caption-left absolute-left'
      return classString
    }
    if (captionAnimatedRight) {
      classString += ' hero-caption-left absolute-right'
      return classString
    }
    if (captionLeft) {
      classString += ' hero-caption-left'
      return classString
    }
    return classString
  }
  return (
    <>
      <div className={getCaptionClassName()}>
        <h1 className="caption-box">{children}</h1>
      </div>
    </>
  )
}

const CaptionGroup = (props) => {
  const { children } = props
  return <div className="caption-group-container">{children}</div>
}

export { CaptionComponent, CaptionGroup }
