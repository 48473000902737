import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'

const Hero = (props) => {
  const {
    caption = 'Parallax Demo',
    captionLeft,
    captionAnimatedLeft,
    captionAnimatedRight,
    children,
  } = props
  const data = useStaticQuery(
    graphql`
      query {
        desktopHeroImage: file(
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          name: { eq: "pyry1" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobileHeroImage: file(
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          name: { eq: "pyry1_mobile" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const heroImageSources = [
    data.mobileHeroImage.childImageSharp.fluid,
    {
      ...data.desktopHeroImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const getHeroContent = () => {
    if (children) {
      return children
    } else {
      return (
        <div className={getCaptionClassName()}>
          <h1 className="caption-box">{caption}</h1>
        </div>
      )
    }
  }

  const getCaptionClassName = () => {
    let className = 'container caption '
    if (captionAnimatedLeft || captionAnimatedRight) {
      className += ' animated-caption'
    }
    if (captionAnimatedLeft) {
      className += ' hero-caption-left absolute-left'
      return className
    }
    if (captionAnimatedRight) {
      className += ' hero-caption-left absolute-right'
      return className
    }
    if (captionLeft) {
      className += ' hero-caption-left'
      return className
    }
    return className
  }
  return (
    <div className={`hero-container hero-active`}>
      <BackgroundImage
        Tag="section"
        className={`hero-image`}
        fluid={heroImageSources}
      ></BackgroundImage>
      {getHeroContent()}
    </div>
  )
}

export { Hero }
